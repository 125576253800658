import { useTheme } from 'styled-components'

import { FormattedMessage } from 'react-intl'

import { Button } from '@/lib/core/components/Button'
import { CheckCircleIcon } from '@/lib/core/components/Icon'
import { InfoModal } from '@/lib/core/components/Modal'

import { BridgeId } from '@/lib/features/Assets/interfaces'

import messages from './SuccessModal.messages'

export type SuccessModalProps = {
  assetId?: BridgeId
  description?: string | null
  isActive: boolean
  onClose: () => void
}

export const SuccessModal = ({ assetId, description, isActive, onClose }: SuccessModalProps) => {
  const { colors } = useTheme()

  return (
    <InfoModal
      isActive={isActive}
      headingIcon={<CheckCircleIcon size="48" color={colors.primary} />}
      title={<FormattedMessage {...messages.title} />}
      actions={
        <Button fitToContent minWidth size="large" variant="secondary" onClick={onClose}>
          <FormattedMessage {...messages.done} />
        </Button>
      }
    >
      <div>
        <FormattedMessage
          {...messages.description}
          tagName="p"
          values={{
            description,
          }}
        />
        <FormattedMessage
          {...messages.assetId}
          tagName="p"
          values={{
            id: assetId,
          }}
        />
      </div>
    </InfoModal>
  )
}

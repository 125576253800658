import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash-es/get'

import { FormattedMessage, useIntl } from 'react-intl'

import { useAppDispatch } from '@/lib/core/store/store'
import { SelectDefault, SelectOption } from '@/lib/core/components/Select'
import { FieldError, FieldErrorMessage, Label } from '@/lib/core/components/TextField/TextField.styles'

import {
  getAssetFormOptionSelector,
  setAssetFormSelectedOption,
  setAssetFormType,
} from '@/lib/features/Assets/store'
import {
  AssetTypeOptions,
  AssetTypeValues,
} from '@/lib/features/Assets/components/AddAssetModal/constants'
import { FormType } from '@/lib/features/Assets/components/AddAssetModal/interfaces'
import messages from '@/lib/features/Assets/components/AddAssetModal/AddAssetModal.messages'

export type SelectTypeControlProps = {
  disabled?: boolean
  error?: string
}

export const SelectTypeControl = ({ disabled, error }: SelectTypeControlProps) => {
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const defaultValue = useSelector(getAssetFormOptionSelector)

  const options = useMemo(
    () =>
      AssetTypeOptions.map(({ translationKey, value }) => ({
        label: formatMessage(get(messages, translationKey)),
        value,
      })),
    [],
  )

  const onChange = (option: SelectOption) => {
    dispatch(setAssetFormSelectedOption(option))

    if (option.value === AssetTypeValues.PTU) {
      dispatch(setAssetFormType(FormType.PTU))
    }

    if (option.value === AssetTypeValues.VEHICLE) {
      dispatch(setAssetFormType(FormType.VEHICLE_BASIC))
    }
  }

  return (
    <>
      <Label $disabled={disabled} $displayInline $required htmlFor="assetType">
        <FormattedMessage {...messages.whatTrack} />
      </Label>
      <SelectDefault
        aria-labelledby="assetType"
        id="add-asset-form-asset"
        name="assetType"
        isSearchable={false}
        isDisabled={disabled}
        defaultValue={defaultValue}
        // @ts-expect-error Not sure how this was setup originally but it's working
        onChange={onChange}
        options={options}
        placeholder={formatMessage(messages.select)}
      />
      <FieldError>
        {error && <FieldErrorMessage $displayInline>{error}</FieldErrorMessage>}
      </FieldError>
    </>
  )
}

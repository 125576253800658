import { useTheme } from 'styled-components'

import { FormattedMessage, useIntl } from 'react-intl'

import { InfoModal } from '@/lib/core/components/Modal'
import { CheckCircleIcon } from '@/lib/core/components/Icon'

import messages from '@/lib/features/Contacts/contacts.messages'

export type ContactSuccessModalProps = { userInput?: string; onClose: () => void }

export const ContactSuccessModal = ({ userInput, onClose }: ContactSuccessModalProps) => {
  const { formatMessage } = useIntl()
  const { colors } = useTheme()

  return (
    <InfoModal
      isActive={!!userInput}
      onConfirm={onClose}
      confirmLabel={formatMessage(messages.successModalButtonLabel)}
      title={formatMessage(messages.successModalTitle)}
      headingIcon={<CheckCircleIcon size="40" color={colors.primary} />}
    >
      <FormattedMessage {...messages.successMessageTop} values={{ userInput }} />
      <br />
      <FormattedMessage {...messages.successMessageBottom} />
    </InfoModal>
  )
}

import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { FormattedMessage, useIntl } from 'react-intl'

import { useAppDispatch } from '@/lib/core/store/store'
import { RequestStatus } from '@/lib/core/interfaces/common'
import { FeatureModal } from '@/lib/core/components/Modal'
import { TruckIcon } from '@/lib/core/components/Icon'

import {
  getAssetFormSelector,
  getAssetFormStatusSelector,
  getTrackingSources,
  getTrackingSourceStatusSelector,
  resetAddFormState,
  setAssetFormType,
} from '@/lib/features/Assets/store'
import { AddAssetPTUResponse, AddAssetVehicleResponse } from '@/lib/features/Assets/interfaces'
import { SuccessModal } from '@/lib/features/Assets/components/SuccessModal'
import { FormType } from '@/lib/features/Assets/components/AddAssetModal/interfaces'
import {
  PTUForm,
  SelectTypeControl,
  VehicleBasicForm,
  VehicleDetailForm,
} from '@/lib/features/Assets/components/AddAssetModal/components'
import {
  FormLegend,
  SelectTypeControlWrapper,
  Wrapper,
} from '@/lib/features/Assets/components/AddAssetModal/AddAssetModal.styles'
import messages from '@/lib/features/Assets/components/AddAssetModal/AddAssetModal.messages'

import { FeatureTypes } from '@/lib/utils/features'

export type AddAssetModalProps = {
  isActive: boolean
  onClose?: (withSuccess?: boolean) => void
}

export const AddAssetModal = ({ isActive, onClose = () => null }: AddAssetModalProps) => {
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const trackingSourceStatus = useSelector(getTrackingSourceStatusSelector)
  const formStatus = useSelector(getAssetFormStatusSelector)

  const isSubmitted = useMemo(() => formStatus === RequestStatus.LOADING, [formStatus])

  const { formType, steps } = useSelector(getAssetFormSelector)
  const [addedAsset, setAddedAsset] = useState<
    AddAssetPTUResponse | AddAssetVehicleResponse | null
  >(null)

  const headerTitle = useMemo(() => {
    if ([FormType.VEHICLE_BASIC, FormType.VEHICLE_DETAIL].includes(formType)) {
      return formatMessage(messages.headerWithSteps, {
        all: steps?.total,
        step: steps?.current,
      })
    }

    return formatMessage(messages.header)
  }, [formType, steps])

  const goBack = useMemo<(() => void) | null>(() => {
    if (formType === FormType.VEHICLE_DETAIL) {
      return () => dispatch(setAssetFormType(FormType.VEHICLE_BASIC))
    }

    return null
  }, [formType])

  const forms = useMemo(
    () => ({
      [FormType.SELECT_TYPE]: null,
      [FormType.VEHICLE_BASIC]: <VehicleBasicForm />,
      [FormType.VEHICLE_DETAIL]: <VehicleDetailForm onSent={setAddedAsset} />,
      [FormType.PTU]: <PTUForm onSent={setAddedAsset} />,
    }),
    [formType],
  )

  useEffect(() => {
    if (!isActive) {
      dispatch(resetAddFormState())
      setAddedAsset(null)
    }
  }, [isActive])

  useEffect(() => {
    if (trackingSourceStatus !== RequestStatus.SUCCEEDED) {
      dispatch(getTrackingSources())
    }
  }, [])

  if (addedAsset) {
    return (
      <SuccessModal
        isActive={isActive}
        assetId={addedAsset?.bridgeId}
        description={addedAsset?.description}
        onClose={() => {
          setAddedAsset(null)
          onClose(true)
        }}
      />
    )
  }

  return (
    <FeatureModal
      isActive={isActive}
      feature={FeatureTypes.ASSETS}
      title={headerTitle}
      titleIcon={<TruckIcon size="48" />}
      subtitle={formatMessage(messages.subtitle)}
      onClose={() => onClose()}
      onGoBack={goBack}
    >
      <Wrapper>
        <FormLegend>
          <FormattedMessage {...messages.required} />
        </FormLegend>
        <SelectTypeControlWrapper>
          <SelectTypeControl disabled={isSubmitted} />
        </SelectTypeControlWrapper>
        {forms[formType]}
      </Wrapper>
    </FeatureModal>
  )
}

import { defineMessages } from 'react-intl'

export default defineMessages({
  addNew: {
    defaultMessage: 'Add New',
    description: 'Contacts | All new button label',
  },
  addNewFieldLabel: {
    defaultMessage: 'Add new Organisation',
    description: 'Contacts | Add new modal | form field label',
  },
  addNewFieldPlaceholder: {
    defaultMessage: 'Company Bridge ID or Email address',
    description: 'Contacts | Add new modal | form field placeholder',
  },
  addNewHeading: {
    defaultMessage: 'Add a new Contact',
    description: 'Contacts | Add new modal | heading text',
  },
  addNewSubmit: {
    defaultMessage: 'Send request',
    description: 'Contacts | Add new modal | submit button label',
  },
  addNewSubtitle: {
    defaultMessage: 'Connect to an organisation you want to work with',
    description: 'Contacts | Add new modal | heading subtitle',
  },
  allContacts: {
    defaultMessage: 'All Contacts',
    description: 'Contacts | All Contacts tab header',
  },
  pageTitle: {
    defaultMessage: 'Contacts',
    description: 'Contacts | page title',
  },
  required: {
    defaultMessage: 'Required Fields *',
    description: 'Contacts | Add new modal | form legend',
  },
  resendInvitationContent: {
    defaultMessage:
      'Are you sure you want to send another Connection Request to {user}. You will be notified when they accept your invitation.',
    description: 'All contacts table | Resend Invitation Modal | resend invitation model content',
  },
  resendInvitationTitle: {
    defaultMessage: 'Resend Connection Request',
    description: 'All contacts table | Resend Invitation Modal | resend invitation model title',
  },
  resendModalCancelLabel: {
    defaultMessage: 'No',
    description: 'Contacts | Resend Invitation Modal | cancel label',
  },
  resendModalConfirmLabel: {
    defaultMessage: 'Yes',
    description: 'Contacts | Resend Invitation Modal | confirm label',
  },
  revokeInvitationContent: {
    defaultMessage: 'Are you sure you want to revoke your connection with {user}?',
    description: 'All contacts table | Revoke Invitation Modal | resend invitation model content',
  },
  revokeInvitationTitle: {
    defaultMessage: 'Revoke Connection',
    description: 'All contacts table | Revoke Invitation Modal | revoke invitation model title',
  },
  revokeModalCancelLabel: {
    defaultMessage: 'Cancel',
    description: 'Contacts | Revoke Invitation Modal | cancel label',
  },
  revokeModalConfirmLabel: {
    defaultMessage: 'Revoke',
    description: 'Contacts | Revoke Invitation Modal | confirm label',
  },
  successMessageBottom: {
    defaultMessage: 'You will be notified when they accept your invitation.',
    description: 'Contacts | on successful adding contact ',
  },
  successMessageTop: {
    defaultMessage: 'Email sent to: {userInput}.',
    description: 'Contacts | on successful adding contact ',
  },
  successModalButtonLabel: {
    defaultMessage: 'Done',
    description: 'Contacts | Add new modal | button label',
  },
  successModalTitle: {
    defaultMessage: 'Connection Request sent',
    description: 'Contacts | Add new modal | title',
  },
})

export enum AssetTypeValues {
  VEHICLE = 'vehicle',
  PTU = 'ptu',
}

export const AssetTypeOptions = [
  {
    translationKey: 'vehicleOptionLabel',
    value: AssetTypeValues.VEHICLE,
  },
  {
    translationKey: 'ptuOptionLabel',
    value: AssetTypeValues.PTU,
  },
]

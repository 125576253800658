import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { FormattedMessage, useIntl } from 'react-intl'

import { formsMessages } from '@/lib/core/messages'
import { useAppDispatch } from '@/lib/core/store/store'
import { Button } from '@/lib/core/components/Button'
import { SelectField } from '@/lib/core/components/Select'
import { TextField } from '@/lib/core/components/TextField'

import {
  AddAssetPTUPayload,
  AddAssetPTUResponse,
  PTUResponseError,
} from '@/lib/features/Assets/interfaces'
import { addAssetPTU, getTrackingSourceOptionsSelector } from '@/lib/features/Assets/store'
import {
  ErrorBox,
  FormActions,
  FormGrid,
} from '@/lib/features/Assets/components/AddAssetModal/AddAssetModal.styles'
import { PTUFormState } from '@/lib/features/Assets/components/AddAssetModal/interfaces'
import messages from '@/lib/features/Assets/components/AddAssetModal/AddAssetModal.messages'

import { setFormErrors } from '@/lib/utils/forms'

export type PTUFormProps = {
  onSent?: (response: AddAssetPTUResponse) => void
}

export const PTUForm = ({ onSent }: PTUFormProps) => {
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const trackingOptions = useSelector(getTrackingSourceOptionsSelector)

  const schema = useMemo(
    () =>
      yup.object().shape({
        description: yup
          .string()
          .nullable()
          .required(formatMessage(formsMessages.requiredValidation)),
        trackingIdentifier: yup
          .string()
          .nullable()
          .required(formatMessage(formsMessages.requiredValidation)),
        trackingSourceId: yup
          .string()
          .nullable()
          .required(formatMessage(formsMessages.requiredValidation)),
      }),
    [],
  )

  const formMethods = useForm<PTUFormState>({
    defaultValues: {
      description: null,
      trackingIdentifier: null,
      trackingSourceId: null,
    },
    resolver: yupResolver(schema),
  })

  const { formState, handleSubmit, setError } = formMethods
  const { isSubmitted, isSubmitting, isValid } = formState

  const onSubmit = async (values: PTUFormState) => {
    try {
      const response = await dispatch(addAssetPTU(values as AddAssetPTUPayload)).unwrap()

      if (onSent) {
        onSent(response)
      }
    } catch (err) {
      const errors = err as PTUResponseError

      if (errors) {
        setFormErrors(setError, errors)
      }
    }
  }

  return (
    <FormProvider {...formMethods}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <FormGrid>
          <TextField
            id="add-asset-form-description"
            name="description"
            type="text"
            displayInline
            required
            disabled={isSubmitting}
            label={formatMessage(messages.description)}
          />
          <SelectField
            aria-labelledby="trackingSourceId"
            name="trackingSourceId"
            label={formatMessage(messages.source)}
            placeholder={formatMessage(messages.select)}
            displayInline
            required
            disabled={isSubmitting}
            selectProps={{
              isDisabled: isSubmitting,
              isSearchable: false,
              maxMenuHeight: 185,
              menuPlacement: 'bottom',
              options: trackingOptions,
            }}
          />
          <TextField
            id="add-asset-form-trackingIdentifier"
            type="text"
            name="trackingIdentifier"
            displayInline
            required
            disabled={isSubmitting}
            label={formatMessage(messages.trackId)}
          />
        </FormGrid>
        {isSubmitted && !isValid && (
          <ErrorBox>
            <FormattedMessage {...messages.customErrorCommunicate} />
          </ErrorBox>
        )}
        <FormActions>
          <Button
            isLoading={isSubmitting}
            size="large"
            variant="secondary"
            fitToContent
            minWidth
            type="submit"
          >
            <FormattedMessage {...messages.submit} />
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  )
}

import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { FormattedMessage, useIntl } from 'react-intl'

import { formsMessages } from '@/lib/core/messages'
import { useAppDispatch } from '@/lib/core/store/store'
import { Button } from '@/lib/core/components/Button'
import { TextField } from '@/lib/core/components/TextField'

import {
  getAssetFormErrorsSelector,
  getAssetFormStateSelector,
  lockAssetFormType,
  patchAssetFormState,
  setAssetFormType,
} from '@/lib/features/Assets/store'
import {
  ErrorBox,
  FormActions,
  FormGrid,
} from '@/lib/features/Assets/components/AddAssetModal/AddAssetModal.styles'
import {
  FormType,
  VehicleBasicFormState,
} from '@/lib/features/Assets/components/AddAssetModal/interfaces'
import messages from '@/lib/features/Assets/components/AddAssetModal/AddAssetModal.messages'

import { setFormErrors } from '@/lib/utils/forms'

export const VehicleBasicForm = () => {
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const initialValues = useSelector(getAssetFormStateSelector)
  const errors = useSelector(getAssetFormErrorsSelector)

  const schema = useMemo(
    () =>
      yup.object().shape({
        description: yup
          .string()
          .nullable()
          .required(formatMessage(formsMessages.requiredValidation)),
        make: yup.string().nullable().optional(),
        vin: yup.string().nullable().optional(),
      }),
    [],
  )

  const formMethods = useForm<VehicleBasicFormState>({
    defaultValues: {
      description: initialValues?.description || null,
      make: initialValues?.make || null,
      vin: initialValues?.vin || null,
    },
    resolver: yupResolver(schema),
  })
  const { formState, handleSubmit, setError } = formMethods

  const onSubmit = (values: VehicleBasicFormState) => {
    dispatch(patchAssetFormState(values))
    dispatch(lockAssetFormType(FormType.VEHICLE_BASIC))
    dispatch(setAssetFormType(FormType.VEHICLE_DETAIL))
  }

  useEffect(() => {
    if (errors) {
      setFormErrors(setError, errors)
    }
  }, [errors])

  return (
    <FormProvider {...formMethods}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <FormGrid>
          <TextField
            id="add-asset-form-description"
            label={formatMessage(messages.description)}
            displayInline
            name="description"
            type="text"
            required
          />
          <TextField
            id="add-asset-form-vin"
            label={formatMessage(messages.vin)}
            displayInline
            type="text"
            name="vin"
          />
          <TextField
            id="add-asset-form-make"
            label={formatMessage(messages.make)}
            displayInline
            type="text"
            name="make"
          />
        </FormGrid>
        {formState.isSubmitted && !formState.isValid && (
          <ErrorBox>
            <FormattedMessage {...messages.customErrorCommunicate} />
          </ErrorBox>
        )}
        <FormActions>
          <Button minWidth fitToContent size="large" variant="secondary" type="submit">
            <FormattedMessage {...messages.next} />
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  )
}

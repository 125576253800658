import { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { FormattedMessage, useIntl } from 'react-intl'

import { FeatureModal, FeatureModalProps } from '@/lib/core/components/Modal'
import { CheckCircleIcon } from '@/lib/core/components/Icon'
import { TextField } from '@/lib/core/components/TextField'
import { Button } from '@/lib/core/components/Button'
import { formsMessages } from '@/lib/core/messages'
import { useAppDispatch } from '@/lib/core/store/store'

import messages from '@/lib/features/Contacts/contacts.messages'
import { ContactSuccessModal } from '@/lib/features/Contacts/components/ContactSuccessModal'
import {
  AddContactActions,
  AddContactContent,
  AddContactFieldRow,
} from '@/lib/features/Contacts/components/AddContactModal/AddContactModal.styles'
import { FormLegend } from '@/lib/features/Assets/components/AddAssetModal/AddAssetModal.styles'
import { createMyInvitation } from '@/lib/features/Contacts/store'
import { CreateMyInvitationPayload } from '@/lib/features/Contacts/interfaces'
import { PTUResponseError } from '@/lib/features/Assets/interfaces'

import { FeatureTypes } from '@/lib/utils/features'
import { setFormErrors } from '@/lib/utils/forms'

export type AddContactModalValues = CreateMyInvitationPayload
export type AddContactModalProps = Pick<FeatureModalProps, 'isActive' | 'onClose'> & {
  onSuccess?: (value: string) => void
}

export const AddContactModal = ({
  isActive,
  onClose = () => null,
  onSuccess = () => null,
}: AddContactModalProps) => {
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        organizationToIdOrEmail: yup
          .string()
          .test('email-or-id', formatMessage(formsMessages.emailOrNumberId), (value) => {
            const isNumber = !Number.isNaN(Number(value))

            return (
              yup
                .string()
                .email()
                .isValidSync(value as string) || isNumber
            )
          })
          .required(formatMessage(formsMessages.requiredValidation)),
      }),
    [],
  )
  const formMethods = useForm<AddContactModalValues>({
    resolver: yupResolver(validationSchema),
  })
  const { handleSubmit, formState, setError, reset } = formMethods
  const [successUserInput, setSuccessUserInput] = useState('')

  const onSubmit = async (values: AddContactModalValues) => {
    try {
      await dispatch(createMyInvitation(values)).unwrap()
      setSuccessUserInput(values.organizationToIdOrEmail)
      onSuccess(values.organizationToIdOrEmail)
    } catch (err) {
      const errors = err as PTUResponseError

      if (errors) {
        setFormErrors(setError, errors)
      }
    }
  }

  useEffect(() => {
    if (!isActive) {
      reset()
      setSuccessUserInput('')
    }
  }, [isActive])

  if (successUserInput) {
    return <ContactSuccessModal userInput={successUserInput} onClose={() => onClose(true)} />
  }

  return (
    <FeatureModal
      feature={FeatureTypes.CONTACTS}
      isActive={isActive}
      title={formatMessage(messages.addNewHeading)}
      onClose={() => onClose()}
      titleIcon={<CheckCircleIcon size="40" />}
      subtitle={formatMessage(messages.addNewSubtitle)}
    >
      <AddContactContent>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
            <FormLegend>
              <FormattedMessage {...messages.required} />
            </FormLegend>
            <AddContactFieldRow>
              <TextField
                name="organizationToIdOrEmail"
                label={formatMessage(messages.addNewFieldLabel)}
                required
                displayInline
                disabled={formState.isSubmitting}
                placeholder={formatMessage(messages.addNewFieldPlaceholder)}
              />
            </AddContactFieldRow>
            <AddContactActions>
              <Button
                type="submit"
                variant="secondary"
                size="large"
                fitToContent
                minWidth
                disabled={formState.isSubmitting}
              >
                <FormattedMessage {...messages.addNewSubmit} />
              </Button>
            </AddContactActions>
          </form>
        </FormProvider>
      </AddContactContent>
    </FeatureModal>
  )
}

import { defineMessages } from 'react-intl'

export default defineMessages({
  assetId: {
    defaultMessage: 'Asset ID: {id}',
    description: 'asset added successfully modal asset id text',
  },
  description: {
    defaultMessage: 'You can now view and share {description}',
    description: 'asset added successfully modal description',
  },
  done: {
    defaultMessage: 'Done',
    description: 'asset added successfully modal close button',
  },
  title: {
    defaultMessage: 'Asset added successfully',
    description: 'asset added successfully modal title',
  },
})

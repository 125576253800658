import styled from 'styled-components'

export const AddContactContent = styled.section`
  padding: ${({ theme }) => theme.layout.spacing(6)};
`
export const AddContactFieldRow = styled.div`
  display: grid;
  align-items: center;
  gap: ${({ theme }) => theme.layout.spacing(2)};
  grid-template-columns: 220px 400px auto;
  margin-bottom: ${({ theme }) => theme.layout.spacing(6)};
`
export const AddContactActions = styled.footer`
  display: flex;
  justify-content: flex-end;
`

import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { FormattedMessage, useIntl } from 'react-intl'

import { useAppDispatch } from '@/lib/core/store/store'
import { formsMessages } from '@/lib/core/messages'
import { Button } from '@/lib/core/components/Button'
import { TextField } from '@/lib/core/components/TextField'
import { SelectField } from '@/lib/core/components/Select'

import {
  addAssetVehicle,
  getAssetFormErrorsSelector,
  getAssetFormStateSelector,
  getLockedAssetFormsSelector,
  getTrackingSourceOptionsSelector,
  patchAssetFormState,
  releaseAllAssetForms,
  setAssetFormErrors,
  setAssetFormType,
} from '@/lib/features/Assets/store'
import {
  AddAssetVehicleError,
  AddAssetVehiclePayload,
  AddAssetVehicleResponse,
} from '@/lib/features/Assets/interfaces'
import {
  ErrorBox,
  FormActions,
  FormGrid,
} from '@/lib/features/Assets/components/AddAssetModal/AddAssetModal.styles'
import {
  FormType,
  VehicleDetailFormState,
} from '@/lib/features/Assets/components/AddAssetModal/interfaces'
import messages from '@/lib/features/Assets/components/AddAssetModal/AddAssetModal.messages'

import { setFormErrors } from '@/lib/utils/forms'

export type VehicleDetailFormProps = {
  onSent?: (response: AddAssetVehicleResponse) => void
}

export const VehicleDetailForm = ({ onSent }: VehicleDetailFormProps) => {
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const trackingOptions = useSelector(getTrackingSourceOptionsSelector)
  const currentState = useSelector(getAssetFormStateSelector)
  const lockedForms = useSelector(getLockedAssetFormsSelector)
  const errors = useSelector(getAssetFormErrorsSelector)

  const schema = useMemo(
    () =>
      yup.object().shape({
        model: yup.string().nullable().optional(),
        plateNumber: yup
          .string()
          .nullable()
          .required(formatMessage(formsMessages.requiredValidation)),
        trackingIdentifier: yup
          .string()
          .nullable()
          .required(formatMessage(formsMessages.requiredValidation)),
        trackingSourceId: yup
          .number()
          .nullable()
          .required(formatMessage(formsMessages.requiredValidation)),
      }),
    [],
  )

  const formMethods = useForm<VehicleDetailFormState>({
    defaultValues: {
      model: currentState?.model || null,
      plateNumber: currentState?.plateNumber || null,
      trackingIdentifier: currentState?.trackingIdentifier || null,
      trackingSourceId: currentState?.trackingSourceId || null,
    },
    resolver: yupResolver(schema),
  })

  const { formState, getValues, handleSubmit, setError } = formMethods
  const { isSubmitted, isSubmitting, isValid } = formState

  const onSubmit = async (values: VehicleDetailFormState) => {
    dispatch(patchAssetFormState(values))
    dispatch(setAssetFormErrors(null))

    try {
      const response = await dispatch(
        addAssetVehicle({
          ...currentState,
          ...values,
        } as AddAssetVehiclePayload),
      ).unwrap()

      if (onSent) {
        onSent(response)
      }
    } catch (err) {
      const apiErrors = err as AddAssetVehicleError

      dispatch(releaseAllAssetForms())
      dispatch(setAssetFormErrors(apiErrors))
    }
  }

  useEffect(() => {
    if (errors) {
      const thisFormKeys = Object.keys(getValues())
      const errorEntries = Object.entries(errors).filter(([key]) => key !== '_error')
      const firstStepErrors = errorEntries.some(([fieldKey]) => !thisFormKeys.includes(fieldKey))

      if (firstStepErrors) {
        if (!lockedForms.includes(FormType.VEHICLE_BASIC)) {
          dispatch(setAssetFormType(FormType.VEHICLE_BASIC))
        }
      } else {
        setFormErrors(setError, errors)
      }
    }
  }, [errors])

  return (
    <FormProvider {...formMethods}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <FormGrid>
          <TextField
            id="add-asset-form-model"
            type="text"
            name="model"
            displayInline
            disabled={isSubmitting}
            label={formatMessage(messages.model)}
          />
          <TextField
            id="add-asset-form-plateNumber"
            type="text"
            name="plateNumber"
            displayInline
            required
            disabled={isSubmitting}
            label={formatMessage(messages.plateNumber)}
          />
          <SelectField
            aria-labelledby="trackingSourceId"
            name="trackingSourceId"
            label={formatMessage(messages.source)}
            placeholder={formatMessage(messages.select)}
            displayInline
            required
            disabled={isSubmitting}
            selectProps={{
              defaultValue: trackingOptions.find(
                ({ value }) => value === currentState?.trackingSourceId,
              ),
              isDisabled: isSubmitting,
              isSearchable: false,
              maxMenuHeight: 185,
              menuPlacement: 'bottom',
              options: trackingOptions,
            }}
          />
          <TextField
            id="add-asset-form-trackingIdentifier"
            type="text"
            name="trackingIdentifier"
            required
            displayInline
            disabled={isSubmitting}
            label={formatMessage(messages.trackId)}
          />
        </FormGrid>
        {isSubmitted && !isValid && (
          <ErrorBox>
            <FormattedMessage {...messages.customErrorCommunicate} />
          </ErrorBox>
        )}
        <FormActions>
          <Button
            isLoading={isSubmitting}
            minWidth
            fitToContent
            size="large"
            variant="secondary"
            type="submit"
          >
            <FormattedMessage {...messages.submit} />
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  )
}

import { defineMessages } from 'react-intl'

export default defineMessages({
  back: {
    defaultMessage: 'Back',
    description: 'add asset form | back button',
  },
  customErrorCommunicate: {
    defaultMessage: 'Adjust form fields and try again',
    description: 'add asset form | custom error text in additional window',
  },
  description: {
    defaultMessage: 'Vehicle Description',
    description: 'add asset form label | vehicle description',
  },
  header: {
    defaultMessage: 'Add a New Asset',
    description: 'add asset modal title',
  },
  headerWithSteps: {
    defaultMessage: 'Add a New Asset (Step {step}/{all})',
    description: 'add asset modal title with step',
  },
  kind: {
    defaultMessage: 'Vehicle Kind',
    description: 'add asset form label | vehicle kind',
  },
  licenseExpireAt: {
    defaultMessage: 'License Expire At',
    description: 'add asset form label | license expire date',
  },
  make: {
    defaultMessage: 'Vehicle Make',
    description: 'add asset form label | vehicle description',
  },
  model: {
    defaultMessage: 'Vehicle Model',
    description: 'add asset form label | vehicle model',
  },
  next: {
    defaultMessage: 'Next',
    description: 'add asset form | next button',
  },
  plateNumber: {
    defaultMessage: 'Vehicle License Plate',
    description: 'add asset form label | vehicle license plate',
  },
  ptuOptionLabel: {
    defaultMessage: 'Portable Tracking Unit',
    description: 'asset type select option | ptu label',
  },
  required: {
    defaultMessage: 'Required Fields *',
    description: 'add asset form, required fields paragraph',
  },
  select: {
    defaultMessage: 'Select',
    description: 'add asset form select placeholder',
  },
  source: {
    defaultMessage: 'Tracking Source',
    description: 'add asset form label | tracking source',
  },
  submit: {
    defaultMessage: 'Submit',
    description: 'add asset form | submit button',
  },
  subtitle: {
    defaultMessage: 'Add a new asset owned by me',
    description: 'add asset modal subtitle',
  },
  trackId: {
    defaultMessage: 'Tracking Identifier',
    description: 'add asset form label | tracking identifier',
  },
  vehicleOptionLabel: {
    defaultMessage: 'Vehicle',
    description: 'asset type select option | vehicle label',
  },
  vin: {
    defaultMessage: 'Vehicle Vin Number',
    description: 'add asset form label | vehicle vin',
  },
  whatTrack: {
    defaultMessage: 'What are you tracking?',
    description: 'add asset form label | asset type vehicle or PTU',
  },
})
